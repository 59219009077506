.unit-feedbacks-v1 {
	.ptitle {
		display: inline-block;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;

		.rating {
			padding: 0 30px 15px 0;
			width: 29%;

			@media (max-width:550px) {
				width: 45%;
			}

			.fa {
				color: var(--color-primary)
			}
		}
	}


	.showLess {
		position: relative;

		&.showFull {
			.widget {
				.widget-block {
					height: auto;
					overflow: visible;
				}
			}


			.hide-show-btn {

				&:after {
					content: "";
				}
			}
		}

		.widget {
			.widget-block {
				height: 160px;
				overflow: hidden;

				@media (max-width:550px) {
					height: 290px;
				}
			}
		}

		.hide-show-btn {
			position: absolute;
			bottom: -14px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--color-white);
			display: inline-block;
			border: 1px solid var(--unit-navbar-bg-color);
			font-size: var(--font-size-md);

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "";
				margin-left: 10px;
				margin-left: 5px;
				font-family: "Font Awesome 6 Sharp";
			}
		}
	}

	.feedbacks {

		clear: left;
		padding-top: 15px;

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 30px;

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}